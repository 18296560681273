import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
// import DroneInfoItem from './DroneInfoItem';

import GMap from './GMap';

import './ViewOnlyMode.scss'
// import AltimeterInstrument from './AltimeterInstrument';
import AttitudeInstrument from './AttitudeInstrument';
// import AirSpeedInstrument from './AirSpeedInstrument';
// import TurnCoordinatorInstrument from './TurnCoordinatorInstrument';
// import HeadingInstrument from './HeadingInstrument';
// import VariometerInstrument from './VariometerInstrument';
import Spinner from 'react-bootstrap/Spinner'
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomAlertBox from './CustomAlert/CustomAlertBox';
import LocationDropDown from './LocationDropDown/LocationDropDown';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import InfoIndicatorBox from './InfoIndicatorBox/InfoIndicatorBox';
import io from 'socket.io-client';
import axios from 'axios';
import { backEndIP, clientSocketIP } from './MyConfiguration';
import GMapViewOnly from './GMapViewOnly';
import { logoutAction } from './utility';
import { Avatar, Dropdown } from 'rsuite';
// https://ws5002.dineshborse.in
// const socket = io("http://100.20.224.14:5002", { autoConnect: false, transports: ["websocket", "polling"] });



const LeftSidePanelViewOnly = ({ droneInfo, userCookie, socket, removeCookie, themeGlobal, setCustomAlertVisibility, setAlertBoxMessage }) => {
    const navigate = useNavigate();
    const InfoBoxDataArray = [
        {
            posi: 1,
            label: "Altitude (m)",
            value: `${droneInfo.alt ? droneInfo.alt : "00"}`
        },
        {
            posi: 2,
            label: "Speed (Km/hr)",
            value: `${droneInfo.g_speed ? droneInfo.g_speed : "00"}`
        },
        {
            posi: 5,
            label: "ETA (mm:ss)",
            value: `${droneInfo.eta ? droneInfo.eta : "00:00"}`
        },
        {
            posi: 6,
            label: "Distance (Km)",
            value: `${droneInfo.distance ? droneInfo.distance : "00"}`
        },

    ]
    // console.log("userCookie", userCookie.useremail)

    return (<div className='view-sidebar'>
        <div className='view-sidebar__logodiv'>
            {/* <img className='view-sidebar__logodiv__logoimg' src='https://uploads-ssl.webflow.com/6284afcd3c8fe34dca52d136/62bfd7e69c25897f423bcdac_TechEagle%20new%20logo.svg' alt='logo' /> */}
            <img className='view-sidebar__logodiv__logoimg' src={themeGlobal.nameLogo} alt='logo'
                onClick={() => { navigate("/dashboard") }} />
            {/* <div className='view-sidebar__logodiv__back'>
            <img src='left_arrow_icon.svg' alt={""} style={{ height: "20px", width: "20px" }} />
            Back</div> */}
        </div>
        <div className='view-sidebar__logodiv-border'></div>
        {/* <div className='view-sidebar__DroneName'>VERTIPLANE X3 01 Drone</div> */}
        {/* <div className='view-sidebar__ElapsedTimeContainer'>
        Elapsed Time - {"00:00"}
        </div> */}
        {/* <img className='aiimslogo_viewOnly' src='PHC-logo.svg' alt='logo'/>*/}
        <img className='aiimslogo_viewOnly' src={userCookie.useremail === "rishabh.sharma@techeagle.in" ? `${backEndIP}/amazon_logo.webp` : `${backEndIP}/PHC-logo.svg`} alt='logo' />
        <div className='view-sidebar__infoContainer'>
            {InfoBoxDataArray.map((inf) => {
                return <InfoIndicatorBox
                    infoLabel={inf.label} infoValue={inf.value}
                    _width={"42%"} _height={"42%"}
                ></InfoIndicatorBox>
            })}
        </div>

        <div className='view-sidebar__logout'
            onClick={() => {
                setAlertBoxMessage({
                    message:
                        "Are you sure you want Logout?",
                    func: () => { logoutAction(removeCookie, navigate, socket) }
                });
                setCustomAlertVisibility(true);
            }}>
            Logout
        </div>
    </div>);
}

const ViewOnlyMode = ({ userCookie, setuserCookie, removeCookie, themeGlobal }) => {

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(window.document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    setuserCookie("token", getCookie("token"), { path: "/" })
    setuserCookie("username", getCookie("username"), { path: "/" })
    setuserCookie("useremail", getCookie("useremail"), { path: "/" })
    setuserCookie("flightId", getCookie("flightId"), { path: "/" })
    setuserCookie("orderNo", getCookie("orderNo"), { path: "/" })
    const [lastPong, setLastPong] = useState(null);


    const [droneInfo, setDroneInfo] = useState({});
    const [isConnected, setIsConnected] = useState(false);
    // const DroneInfoKeys = ["mode", "roll", "pitch", "yaw", "heading", "lat",
    //     "long", "alt"];
    const themesAvailable = {
        light: {
            "--primaryColor": "white",
            "--secondaryColor": "#F2F2F2",
            "--primaryTextColor": "#08030A",
            "--secondaryTextColor": "#e2e2e2"
        },
        dark: {
            "--primaryColor": "#000",
            "--secondaryColor": "#0B0B0B",
            "--primaryTextColor": "white",
            "--secondaryTextColor": "#e2e2e2"
        }
    };


    // const themeSelected = useContext(ThemeContext);
    const [ConnectionStatus, setConnectionStatus] = useState(false);
    const [ConnectingSpinner, setConnectingSpinner] = useState(false);
    const [ConnectBtnString, setConnectBtnString] = useState("Connect Drone");
    const [currentMessage, setCcurrentMessage] = useState("For starting Please connect to Drone")
    const [customAlertVisibility, setCustomAlertVisibility] = useState(false);
    const [alertBoxMesage, setAlertBoxMessage] = useState({ message: "", func: () => { } });

    // const [dimen, setDimen] = useState({ height: window.innerHeight, width: window.innerHeight })

    const [startLocation, setStartLocation] = useState("HUB");
    const [endLocation, setEndLocation] = useState("HUB");
    const [routePoints, SetRoutePoints] = useState([])
    const [centerToDrone, SetCenterToDrone] = useState(false);
    const [gcsLog, setGCS_Log] = useState([]);

    // const [nameLogo, setNameLogo] = useState('TE-logoName.svg')

    // const [EagleLogo, SetEagleLogo] = useState('TechEagle_Logo.svg')

    // const [theme, setTheme] = useState(-1)
    // const MovingRingRef = useRef();

    const AllLocations = ["HUB", "L1", "L2", "L3", "L4", "L5"]
    const navigate = useNavigate();

    const handleLogoutClick = async () => {
        await removeCookie("token");
        await removeCookie("username");
        await removeCookie("useremail");
        await removeCookie("flightId");
        await removeCookie("orderNo");
        // document.cookie ="";
        socket.close()
        navigate("/")
    }

    const RefLogBox = useRef();

    useEffect(() => {
        RefLogBox.current.scrollIntoView({ behavior: "smooth" });
    }, [gcsLog.length])

    const [socket, setSocket] = useState(io(clientSocketIP, {
        autoConnect: false,
        transports: ["websocket", "polling"],
        auth: {
            token: userCookie.token,
            order_no: userCookie.orderNo,
            flightId: userCookie.flightId,
        }
    }))

    const renderToggle = props => (
        <Avatar circle {...props} src="" style={{ backgroundColor: "#FD6125" }} />
    );

    useEffect(()=>{
        const myFunc = async() => {
            // console.log("getCookie(token) =", getCookie("token"), userCookie.token)
            // console.log("document.cookie =", document.cookie,)
            // console.log("window.document.cookie =", window.document.cookie)
            if (userCookie.token && userCookie.token !== "" || getCookie("token") !== "") {
                // console.log("getCookie(token) =", getCookie("token"), userCookie.token)
                axios({
                    url: backEndIP + "/user/verifytoken",
                    method: "POST",
                    headers: {
                        authorization: userCookie.token,
                    },
                    data: {
                        order_no: userCookie.orderNo,
                        flightId: userCookie.flightId,
                    }
                }).then(async (loginData) => {
                    await setuserCookie("token", loginData.data.token, { path: "/", maxAge: 60 * 1000 })
                    await setuserCookie("username", loginData.data.userName, { path: "/", maxAge: 60 * 1000 })
                    await setuserCookie("useremail", loginData.data.userEmail, { path: "/", maxAge: 60 * 1000 })
                    // setuserCookie("token", getCookie("token"), { path: "/" })
                    // setuserCookie("username", getCookie("username"), { path: "/" })
                    // setuserCookie("useremail", getCookie("useremail"), { path: "/" })
                    // setuserCookie("flightId", getCookie("flightId"), { path: "/" })
                    // setuserCookie("orderNo", getCookie("orderNo"), { path: "/" })
                    setSocket(io(clientSocketIP, {
                        autoConnect: false,
                        transports: ["websocket", "polling"],
                        auth: {
                            token: userCookie.token,
                            order_no: getCookie("orderNo") === "" ? "empty" : getCookie("orderNo"),
                            flightId: getCookie("flightId") === "" ? "empty" : getCookie("flightId"),
                        }
                    }))
                }).catch(async(err) => {
                    // window.alert("login again")
                    console.log(err);
                    await removeCookie("token");
                    await removeCookie("username");
                    await removeCookie("useremail");
                    await removeCookie("flightId");
                    await removeCookie("orderNo");
                    // document.cookie ="";
                    socket.disconnect();
                    socket.close();
                    navigate("/")
    
                });
            }
            else {
                console.log("exe else part")
                await removeCookie("token");
                await removeCookie("username");
                await removeCookie("useremail");
                await removeCookie("flightId");
                await removeCookie("orderNo");
                // document.cookie ="";
                socket.disconnect();
                socket.close();
                navigate("/")
            }
    
    
        }
        myFunc()
    }, [])


    useEffect(() => {
        socket.on("connect", () => {
            setIsConnected(true);
            socket.emit("upgrade");
            setConnectBtnString("Disconnect");
            gcsLog.push(
                [new Date().toTimeString().split("(")[0], "connected to server"]
                // `${new Date().toTimeString().split("(")[0]}: ${"cconnect dronnected to server"}`
            );
        });

        socket.on("disconnect", () => {
            setIsConnected(false);
            gcsLog.push(
                [new Date().toTimeString().split("(")[0], "disconnected from server"]
                // `${new Date().toTimeString().split("(")[0]}: ${"disconnected from server"}`
            );
            setConnectBtnString("Connect Server");
        });

        socket.on("pong", () => {
            setLastPong(new Date().toISOString());
        });
        socket.on("server:monitor_data", (data) => {
            console.log(data);
            // if (checklistDone) {
            setDroneInfo(data);
            // }
        });
        socket.on("server:set_route", (data) => {
            console.log(data);

            SetRoutePoints((prev) => { return data })
        });
        socket.on("server:gcs_log", (data) => {

            gcsLog.push([new Date().toTimeString().split("(")[0], data]
                // `${new Date().toTimeString().split("(")[0]}: ${data}`
            );
            // gcsLog.push(data.split(":"));
        });
        socket.on("server:setFlightOver", data => {
            gcsLog.push([new Date().toTimeString().split("(")[0], "Drone reached at destination lacation"]
            );
            setTimeout(() => {
                handleLogoutClick();
            }, 2000);
        })


        return () => {
            socket.off("connect");
            socket.off("disconnect");
            socket.off("pong");
            socket.off("server:monitor_data");
            socket.off("server:gcs_log");
            socket.off("server:set_route");
            socket.off("server:setFlightOver");
            socket.close()
        };
    }, [socket]);





    const handleConnectClick = (e) => {
        setConnectingSpinner(true)
        if (isConnected) {
            // setConnectBtnString("Dro");
            socket.disconnect();
        }
        else {
            setConnectBtnString("Connecting");
            socket.connect();
        }
    }



    return (
        <div className='view-page-container'>
            <LeftSidePanelViewOnly
                userCookie={userCookie}
                droneInfo={droneInfo}
                socket={socket} removeCookie={removeCookie}
                themeGlobal={themeGlobal} setCustomAlertVisibility={setCustomAlertVisibility}
                setAlertBoxMessage={setAlertBoxMessage} />
            <div className='view-main-map'>
                <div className='view-main-map__topInfobar'>
                    {/* <div className='view-main-map__search-container'>
                        <img className='view-main-map__search-container__img' src='searchIcon.svg' alt="" />
                        <input className='view-main-map__search-container__search'
                            type={"text"} placeholder={"Search"}></input>
                    </div> */}
                    <div className='view-main-map__topInfobar__connectBtn'
                        onClick={handleConnectClick}
                    // style={{backgroundColor:"#e82222"}}
                    >
                        {ConnectBtnString}
                    </div>
                    <div className='view-sidebar__DroneName'>VERTIPLANE X3</div>
                    <div className='view-main-map__topInfobar__instuctionNStatus'>
                        {/* {ConnectingSpinner && <Spinner animation="border" role="status" variant='danger'>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>} */}

                        <div className="home-main-map__topInfobar__instuctionNStatus__message" >

                            {gcsLog.length !== 0 &&
                                gcsLog.map((msg, i) => {
                                    return <div key={`gcsLog${i}`}>
                                        <span style={{ color: "#FD6125" }}><strong>{msg[0]}</strong></span>
                                        <span>: {msg[1]}</span>
                                    </div>;
                                })}
                            <div ref={RefLogBox}></div>
                        </div>
                    </div >


                    {/* <div className='view-main-map__topInfobar__SelectLocations-container'>
                        <div className='view-main-map__topInfobar__SelectLocations-container__locations'>
                            <LocationDropDown AllLocations={AllLocations} setLocation={setStartLocation} label={"Origin : "} />
                            <LocationDropDown AllLocations={AllLocations} setLocation={setEndLocation} label={"Destination : "} />
                        </div>
                        <div className='view-main-map__topInfobar__SelectLocations-container__buttons'>
                            <div className='view-main-map__topInfobar__SelectLocations-container__buttons__btn'
                                onClick={handleSetLocationsClick}
                            >Set Locations</div>
                        </div>
                    </div> */}


                    <div className='view-main-map__topInfobar__user'>

                        {/* <div className='view-main-map__topInfobar__user__proImg'>
                            D
                        </div> */}
                        <div className='view-main-map__topInfobar__user__proImg'>
                        <Dropdown renderToggle={renderToggle}
                        // style={{ position: 'absolute', top: '0px', right: '10px' }}
                        placement="bottomEnd" 
                        >
                            <Dropdown.Item panel style={{ padding: 0, width: 100 }}>
                                {/* <p>Signed in as</p> */}
                                {/* <strong>{userCookie.username}</strong> */}
                            </Dropdown.Item>
                            {/* <Dropdown.Separator /> */}
                            <Dropdown.Item>{`Your profile`}</Dropdown.Item>
                            {/* <Dropdown.Item>Your stars</Dropdown.Item>
                        <Dropdown.Item>Your Gists</Dropdown.Item> */}
                           <Dropdown.Item
                           onClick={() => {
                            setAlertBoxMessage({
                                message:
                                    "Are you sure you want to navigate to the Clearsky Training Portal?",
                                func: () => {
                                    setCustomAlertVisibility(false);
                                    // window.location.href = "https://training.clearsky.techeagle.org/";
                                    window.open("https://training.clearsky.techeagle.org/", "_blank");
                                    }
                            });
                            setCustomAlertVisibility(true);
                        }}
                           >Get Training</Dropdown.Item>
                            <Dropdown.Separator />
                            <Dropdown.Item><a href="https://forms.gle/PdXdQM5Lfnxv5Bc3A" target='blank' className='supportlink'>Support</a></Dropdown.Item>
                            {/* <Dropdown.Item>Settings</Dropdown.Item> */}
                            <Dropdown.Item onClick={() => {
                                setAlertBoxMessage({
                                    message:
                                        "Are you sure you want Logout?",
                                    func: () => { logoutAction(removeCookie, navigate, socket) }
                                });
                                setCustomAlertVisibility(true);
                            }}>Log Out</Dropdown.Item>
                        </Dropdown>
                        {/* <div className='DashboardPage-container__header__userIcon__username'>{userCookie.username}</div> */}
                    </div>
                        {/* <img src='clear_sky_logo_with_text.svg'
                            style={{
                                height: "80%", backgroundColor: "#FD6125", padding: "5px",
                                borderRadius: "5px"
                            }} /> */}

                    </div>

                </div>
                <div className='view-main-map__GoogleMapContainer'>

                    <GMapViewOnly
                        routePoints={routePoints}
                        droneLat={droneInfo.lat}
                        droneLong={droneInfo.long}
                        heading={droneInfo.heading}
                        centerToDrone={centerToDrone}
                        SetCenterToDrone={SetCenterToDrone}
                        PageOpen={{ name: "viewOnly", cmdFunc: () => { } }} />
                    <div className='view-main-map__GoogleMapContainer__followDroneBtn'
                        onClick={() => { SetCenterToDrone(true) }}
                    >Follow Drone
                    </div>

                </div>
                <div className='view-main-map__footer-container'>
                    <div className='view-main-map__footer-container__copyright'>
                        <img className='view-main-map__footer-container__copyright__company-logo'
                            src={themeGlobal.EagleLogo}
                        ></img>
                        <div className='view-main-map__footer-container__copyright__title'>
                            Copyright&#169; 2024. TechEagle Innovations Pvt.Ltd.
                        </div>

                    </div>
                    {/* <div className='view-main-map__footer-container__themeSwitch'
                        onClick={(e) => {
                            setTheme((prev) => { return prev * (-1) })
                            MovingRingRef.current.style.transform = `translateX(${-10 * theme}px)`
                            // console.log(e.currentTarget.style.tra)
                        }}
                    >
                        <div className='view-main-map__footer-container__themeSwitch__circle'
                            ref={MovingRingRef}
                        >
                        </div>
                    </div> */}
                </div>


            </div>
            {customAlertVisibility && (
                <CustomAlertBox
                    setOpenModal={setCustomAlertVisibility}
                    messageToDisplay={alertBoxMesage}
                />
            )}        </div>
    )
}

export default ViewOnlyMode;